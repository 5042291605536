.player-viewport {
  // position: absolute;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background: no-repeat center/50%
  //   url('https://st.depositphotos.com/1654249/3643/i/950/depositphotos_36433997-stock-photo-3d-cube-or-box-on.jpg');

  canvas {
    width: 100%;
    height: 100%;
  }
}
