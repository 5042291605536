// Header

.main-header {
  background: #fff;
  box-shadow: $elevation1;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 16px;
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 150px;
  }
  .bp3-button {
    margin: 0 0 0 auto;
    border: 1px solid $gray-light-2;
  }
}
