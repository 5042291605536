// Add step button

.add-step-button {
  padding: 4px 7px;
  .bp3-button {
    padding-top: 8px;
    padding-bottom: 8px;
    background: white;
    font-weight: $medium;
  }
}
