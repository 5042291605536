// Save status display

.save-status-display {
  position: absolute;
  font-size: 13px;
  top: 10px;
  right: 10px;
  background: #fff;
  z-index: 99;
  display: inline-flex;
  align-items: center;
  box-shadow: $elevation1;
  border-radius: $border-radius;
  border: 1px solid $gray-light-4;
  padding: 6px 12px;
  color: $gray-1;
  animation: fade-in $general-change;
  .bp3-spinner {
    margin-left: 10px;
    padding: 0;
  }

  .bp3-icon {
    margin-left: 10px;
    animation: scale-up-center $scale-change;
    svg {
      color: $primary;
    }
  }
}
