// Annotation marker container

.annotation-marker-container {
  position: fixed;
  left: 0;
  top: 0;

  .marker-point {
    position: absolute;
    top: -12px;
    left: -12px;
    width: 18px;
    height: 18px;
    border-radius: 25px;
    background-color: white;
    opacity: 0.3;
    border: 2px solid white;
    z-index: 1;
    box-shadow: $elevation2;
    transition: all 0.1s ease;

    .label {
      position: absolute;
      bottom: 27px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      background: white;
      padding: 4px 6px;
      text-align: center;
      display: none;
      box-shadow: $elevation2;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1.2;
      transition: all 0.1s ease;
      color: $black-font;
      min-width: 110px;
    }

    &.visible {
      z-index: 2;
      opacity: 1;
      border: none;

      .label {
        display: block;
      }

      &:hover,
      &.highlighted {
        // cursor: pointer;
        background-color: $primary;
        color: white;
        .label {
          background-color: $primary;
          color: white;
        }
      }
    }
  }
}

.annotation-box {
  padding: 5px;
  border-bottom: 1px solid $gray-light-4;
  margin-bottom: 10px;

  &.highlighted {
    background: rgba(135, 206, 250, 0.1);
    box-shadow: 0px 0px 5px 2px inset $primary;
  }
}

// annotation label submenu

.annotation-label-submenu {
  .bp3-input-group {
    .bp3-input-left-container {
      top: 3px;
    }
  }
}
