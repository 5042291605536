// ------------------------------------
// $BASE
// ------------------------------------

// Global

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: $font-base;
  box-sizing: border-box;
}

body {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

// Content
iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    outline: none;
  }
}

.wrapper {
  max-width: $layout-maxWidth;
  margin: 0 auto;
  width: 100%;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
