// Manager

.manager {
  &-actions {
    margin: 40px auto 0;
    border-radius: 0;
    padding: 12px 12px 12px 16px;
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    background: $gray-light-5;
    border-bottom: 1px solid $gray-light-2;
    .bp3 {
      &-heading {
        margin: 0;
        text-transform: uppercase;
        color: $gray-2;
        font-size: 16px;
      }
      &-control-group {
        margin-left: auto;
      }
    }
  }
  @include mq(desktop) {
    &-actions {
      width: 100%;
    }
  }
}

.trainings-list {
  margin: 0 auto;
  width: calc(100% - 30px);
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  max-height: calc(100vh - 204px);

  .training {
    &-item {
      display: flex;
      align-items: center;
      border-radius: 0;
      border: 1px solid $gray-light-2;
      border-top-color: transparent;
      box-shadow: none;
      p {
        margin: 10px 0;
      }
    }
    &-actions {
      margin-left: auto;
      flex: 0 0 130px;
      padding-left: 10px;

      .bp3 {
        &-button {
          width: 40px;
          height: 40px;
          border: 1px solid $gray-light-2;
          &.bp3-intent-danger {
            path {
              fill: $error;
            }
          }
          &.bp3-intent-primary {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
    &-title {
      flex: 0 0 140px;
      font-size: 18px;
    }
    &-info {
      flex: 1 1 auto;
      padding-left: 10px;
    }
  }
  @include mq(desktop) {
    width: 100%;
  }
}
