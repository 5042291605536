// pulse animation

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($error, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($error, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($error, 0);
  }
}

// Step instructions

.step-instructions {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  height: 62px;
  display: flex;
  flex-direction: row;
  min-width: 300px;

  .bp4-popover2-arrow {
    display: none;
  }

  .bp4-popover2-content {
    margin: 0;
  }

  .step-instructions-text {
    textarea.bp3-input {
      width: 30vw;
      min-width: 100px;
      height: 100%;
      resize: none;
      background: white;
      border-radius: $border-radius-xl;
      font-size: 13px;
    }
  }
}

.step-instructions-narration {
  display: flex;
  flex-direction: row;
  align-items: normal;
  margin-right: 6px;
  .record-microphone,
  .upload-actions {
    margin-left: 6px;
    .bp4-popover2 {
      left: 0;
    }
  }

  svg {
    width: 28px;
    height: 28px;
    color: $primary;
    transform: scale(1.2);
  }

  .bp3-button {
    border-radius: $border-radius-md;
    background: white;
    border-color: $gray-light-3;
    width: 60px;
    height: 60px;
    animation: fade-in $general-change;

    &:hover {
      background: lighten($primary, 49%);
    }
  }

  .spinner-wrapper {
    border-radius: $border-radius-md;
    background: white;
    border: 1px solid $gray-light-3;
    width: 126px;
    height: 60px;
    margin-left: 6px;
    animation: fade-in $general-change;
  }

  .record-microphone {
    .spinner-box {
      position: relative;

      .bp3-spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .recording-hint {
    display: block;
    width: 10px;
    height: 10px;
    background: $error;
    border-radius: 50%;
    box-shadow: 0 0 0 0 $error;
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  .playback-actions {
    display: flex;
    align-items: center;
    .bp3-button {
      margin-left: 6px;
    }
  }
}
