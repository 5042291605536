// Auth layout

.auth {
  $self: &;
  &-container {
    background: $gray-light-5;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  &-form {
    .bp3 {
      &-form-group {
        margin-bottom: 20px;
      }
      &-label {
        font-size: 15px;
      }

      &-button {
        margin-top: 20px;
        background: $primary;
        box-shadow: none;
        &:hover {
          background: darken($primary, 5%);
        }
      }
    }
  }

  &-card {
    width: 90%;
    max-width: 400px;
    margin: 40px auto;
    border-radius: $border-radius;
    border: 1px solid $gray-light-3;
    box-shadow: none;
    padding: 26px;
    transition: none;
  }

  &-title {
    margin: 0 0 10px 0;
    font-size: 20px;
  }

  &-link {
    display: block;
    padding: 16px 0 0 0;
    text-align: center;
    color: $primary;
  }

  @include mq(tablet) {
    margin-top: -40px;
    &-card {
      @include centered(absolute);
      padding: 36px;
      margin-top: -40px;
    }

    &-title {
      font-size: 24px;
    }
  }
}
