@font-face {
  font-family: 'Inter';
  src: url('~/assets/fonts/inter.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('~/assets/fonts/inter.ttf');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('~/assets/fonts/inter.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('~/assets/fonts/inter.ttf');
  font-display: swap;
  font-weight: 700;
}
